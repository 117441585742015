.success {
  position: relative;
  width: 100%;
  height: 100%;
  padding: var(--gap-xl);

  font-size: var(--font-14);
  text-align: start;

  overflow-y: auto;
}

.copy {
  position: absolute;
  right: 0;
}

.copy path {
  stroke: var(--component-border-hover) !important;
}

.copy:hover path {
  stroke: #808080 !important;
}

.copy:active svg {
  filter: drop-shadow(0 0 1rem var(--primary-color)) !important;
}
