.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 40rem;
  height: 100%;
  padding: var(--gap-xl) var(--gap-ml) var(--gap-xl) var(--gap-xl);
  border-left: var(--header-border);
}

.title {
  margin-bottom: var(--gap-md);
}

.text {
  margin-bottom: var(--gap-5xs);
}

.input {
  margin-bottom: var(--gap-5xs);
}

.text-area {
  margin-bottom: var(--gap-xs);
}
.text-area-inner {
  resize: vertical;
}

.storesSelectContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}

.removeStore {
  font-size: 1.4rem;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.select:last-child {
  margin-bottom: 3.2rem;
}

.models-container {
  display: flex;
  gap: var(--gap-3xs);
  margin-bottom: 3.2rem;
}

.model-select {
  /* ограничение для длинных названий моделей */
  /* 12.6rem - ширина свича AUTO/JSON + gap контейнера */
  max-width: calc(100% - 12.6rem);
}

.function-container {
  margin-bottom: 3.2rem;
}

.function-list {
  position: relative;
}

.function-block {
  margin-bottom: var(--gap-2xs);
}

.functions-loading {
  position: absolute;
  inset: -10px;
  display: flex;

  align-items: center;
  justify-content: center;

  background-color: #d9fff17c;
}

.slider-container {
  margin-top: 3.2rem;
  max-width: 32rem;
  margin-bottom: var(--gap-3xl);
}

.temperature-title {
  font-weight: var(--semibold);
  padding: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.temperature-label {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  text-align: end;
}

.bottom-container {
  margin-bottom: var(--gap-4xs);
}

.message-text {
  font-size: var(--font-14);
}
