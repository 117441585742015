.page-layout {
  display: flex;

  height: calc(100vh - var(--header-height));
}

.wrapper {
  padding: var(--gap-xs) var(--gap-xl) var(--gap-xl);
}

.right-block {
  background: var(--bg-stylist-result);
}
