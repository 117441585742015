.button {
  font-size: var(--font-14);
}

.add-button {
  font-size: var(--font-14);
}

.disabled {
  color: var(--text-placeholder);
}
.disabled path {
  stroke: var(--icon-disabled);
}

.pd-block-16 {
  padding-block: var(--gap-md);
}

.pd-block-10 {
  padding-block: var(--gap-2xs);
}
