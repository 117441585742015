.dialog {
  display: flex;
  flex-direction: column;

  width: 46rem;
  padding: var(--gap-xl);
}

.dialog-title {
  font-size: var(--font-18);
  font-weight: var(--medium);
  line-height: var(--line-height-sm);

  margin-bottom: var(--gap-md);
}

.label {
  margin-bottom: var(--gap-5xs);
}

.input-container {
  margin-bottom: var(--gap-md);
}

.dialog-button-container {
  display: flex;
  justify-content: end;
  gap: var(--gap-3xs);
}

.input {
  transition: border-color 0.2s;
}

.input input {
  font-size: var(--font-14);
}

.error {
  border-color: var(--error-color);
}

.error-label {
  margin: var(--gap-5xs);
  color: var(--error-color);

  transition: opacity 0.2s;
}

.hidden {
  opacity: 0;
}
