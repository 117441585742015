.title {
  font-size: var(--font-18);
  font-weight: var(--semibold);
  line-height: var(--line-height-sm);

  margin-bottom: var(--gap-2xl);
}

.barContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  font-size: var(--font-14);
  font-weight: var(--medium);
  line-height: var(--line-height-2xs);

  margin-right: var(--gap-xs);
  width: 15rem;
  text-align: end;
}

.bar {
  height: 2.4rem;
  border-radius: 1.2rem;

  margin-right: var(--gap-3xs);

  transition: width 0.6s ease-in-out;
}

.percentage {
  font-size: var(--font-16);
  font-weight: var(--medium);
  line-height: var(--line-height-xs);
}
