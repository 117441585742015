.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--gap-xl);
}

.modal-title {
  font-size: var(--font-20);
  line-height: var(--line-height-sm);
}

.close-button svg > path {
  stroke: var(--base-float);
}
