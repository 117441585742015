.dialog {
  display: flex;
  flex-direction: column;

  width: 46rem;
  padding: var(--gap-xl);
}

.dialog-title {
  font-size: var(--font-18);
  font-weight: var(--medium);
  line-height: var(--line-height-sm);

  margin-bottom: var(--gap-md);
}

.label {
  margin-bottom: var(--gap-5xs);
}

.input-container {
  margin-bottom: var(--gap-md);
}
.last {
  margin-bottom: var(--gap-3xl);
}

.dialog-button-container {
  display: flex;
  gap: var(--gap-3xs);
}
