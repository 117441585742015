.accordion {
  background-color: transparent;
  padding: 0;

  box-shadow: none;
}
.accordion::before {
  display: none;
}

.header {
  background-color: var(--bg-accordion);
  flex-direction: row-reverse;
  min-height: 5.6rem;
  padding: var(--gap-xs);
  border-radius: var(--radius-md) !important;
}

.header.expanded-header {
  min-height: 5.6rem;
  background-color: var(--primary-light);
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 0 0 var(--gap-3xs) !important;
}

.header-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
}

.details {
  padding: var(--gap-md) 0;
  background-color: transparent;
}
