.input {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
  color: var(--text-primary);

  padding: var(--gap-xs);

  border: var(--component-border);
  border-radius: var(--radius-md);
}

.inner::-webkit-scrollbar {
  width: 1.5rem;
  border-radius: 1rem;
}

.inner::-webkit-scrollbar-thumb {
  border: 0.6rem solid transparent;
  background-clip: padding-box;
  border-radius: 1rem;

  background-color: var(--primary-lighter);

  cursor: pointer;
}

.send {
  padding-right: 4rem;
}

.container {
  position: relative;
  width: 100%;
}

.button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--gap-5xs);
}

.upload-input {
  display: none;
}

.small {
  font-size: var(--font-14) !important;
  line-height: var(--line-height-2xs);
}

.disabled {
  opacity: 0.5;
}
.disabled path {
  stroke: var(--text-placeholder);
}

.icon path {
  stroke: var(--primary-color);
  opacity: 1;
}

.disabled {
  border-color: var(--component-border-disabled);
}

.send-button path {
  stroke: var(--secondary-color);
}
