.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  min-width: 44rem;

  padding: var(--gap-md);

  overflow-y: auto;
  scrollbar-gutter: stable;
}

.title {
  font-size: var(--font-20);
  font-weight: var(--semibold);
  line-height: var(--line-height-lg);

  margin-bottom: var(--gap-xl);
}
h3.title {
  margin-bottom: var(--gap-lg);
}

.select-container {
  padding-right: var(--gap-2xl);
  margin-bottom: var(--gap-5xl);
}

.list {
  list-style-type: none;
  margin-bottom: var(--gap-lg);
}

.add-button {
  margin-bottom: var(--gap-lg);
}

.analyze-button path {
  transition: stroke 0.2s;
}

.disabled path {
  stroke: var(--icon-disabled);
}
