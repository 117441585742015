.container {
  display: flex;
  align-items: center;
  gap: var(--gap-5xs);
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;

  width: 1.6rem;
  height: 1.6rem;

  border: var(--component-border);
  border-radius: var(--radius-xs);

  transition: border-color 0.05s ease-in-out, background-color 0.05s ease-in-out;
}
.checkbox.checked {
  border: none;
  background-color: var(--primary-color);

  background-image: url("../../assets/img/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem 1rem;
}
.container:hover .checkbox {
  border-color: var(--primary-color);
}

.label {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  user-select: none;
}
