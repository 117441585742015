.right-container {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  flex: 1;
  padding: var(--gap-xl) var(--gap-md);

  background-color: var(--bg-secondary);

  border-radius: var(--radius-lg);
  scrollbar-gutter: stable;
  overflow-y: auto;
  max-width: 1px;
}

.right-container .title {
  margin-bottom: var(--gap-lg);
}

.text-area {
  background-color: var(--secondary-color);
  margin-bottom: var(--gap-5xl);
}
.text-area:nth-last-of-type(2) {
  margin-bottom: var(--gap-3xs);
}

.right-container .button {
  font-weight: var(--medium);
  line-height: var(--line-height-xs);

  width: fit-content;

  padding: var(--gap-3xs) var(--gap-xs);
  margin-bottom: var(--gap-5xl);
}

.chart-container {
  margin-bottom: 4rem;

  width: 100%;
  height: fit-content;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.wrapper {
  position: relative;
  padding: var(--gap-xs);
  overflow: auto;
  min-height: 45rem;

  background: var(--base-grey-5);
  border-radius: var(--radius-md);
}

.wrapper > pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: var(--gap-sm);
  line-height: var(--line-height-2xs);
  color: var(--text-primary);
}
