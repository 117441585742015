.user-message {
  max-width: 90%;
  align-self: flex-end;

  padding: var(--gap-xs);

  color: var(--text-primary);
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  background: var(--bg-button-primary);
  border-radius: var(--radius-md) var(--radius-md) 0 var(--radius-md);
}
