.toggle-button {
  display: flex;
  gap: var(--gap-3xs);
  min-width: 12.4rem;

  text-transform: none;
  font-size: var(--font-16);
  font-weight: var(--regular);
  line-height: var(--line-height-sm);

  color: var(--primary-color);

  padding: var(--border-padding) var(--gap-xs);

  border: var(--default-border);
  border-radius: var(--radius-md);

  transition: background-color 0.2s, color 0.2s;
}

.toggle-button path {
  fill: var(--primary-color);
  transition: fill 0.2s;
}

.toggle-button svg {
  width: 1.6rem;
  height: 1.6rem;
}

.toggle-button.selected {
  background-color: var(--primary-color);
  color: var(--secondary-color);

  border-color: transparent;
  padding: var(--gap-3xs) var(--gap-xs);
}
.toggle-button.selected path {
  fill: var(--secondary-color);
}
.toggle-button.selected:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}
