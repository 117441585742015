.wrapper {
  position: relative;
  display: flex;
  gap: var(--gap-3xs);
}

.system-message {
  max-width: 90%;
  align-self: flex-start;

  padding: var(--gap-xs);

  color: var(--text-primary);
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  background: var(--bg-message);
  border-radius: var(--radius-md) var(--radius-md) var(--radius-md) 0;
}

.system-message ol,
.system-message ul {
  margin-left: var(--gap-md);
}

.system-message li {
  white-space: wrap;
  margin-left: var(--gap-md);
  line-height: var(--line-height-xs);
}

.system-message img {
  max-width: 100%;
}

.inner-message * {
  white-space: pre-wrap;
  text-wrap: wrap;

  overflow-wrap: anywhere;
  word-break: break-word; /* fallback */
}

.system-avatar {
  align-self: flex-start;
}

.button {
  width: fit-content;
  height: fit-content;
  align-self: flex-end;
}

.button path {
  stroke: var(--component-border-hover) !important;
}

.button:hover path {
  stroke: #808080 !important;
}

.button:active svg {
  filter: drop-shadow(0 0 1rem var(--primary-color)) !important;
}
