.dialog {
  display: flex;
  flex-direction: column;

  width: 46rem;
  padding: var(--gap-xl);
}

.dialog-title {
  font-size: var(--font-18);
  font-weight: var(--medium);
  line-height: var(--line-height-sm);

  margin-bottom: var(--gap-md);
}

.label {
  margin-bottom: var(--gap-5xs);
}

.input-container {
  margin-bottom: var(--gap-3xs);
}
.input-container:first-of-type {
  margin-bottom: var(--gap-md);
}

.check-container {
  display: flex;
  align-items: center;
  gap: var(--gap-5xs);
  margin-bottom: var(--gap-xl);
}

.check-text {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
}

.dialog-button-container {
  display: flex;
  gap: var(--gap-3xs);
}
