.user-container {
  display: flex;
  align-items: center;
  gap: var(--gap-3xs);
  margin-bottom: var(--gap-md);
}

.icon-button path {
  stroke: transparent;
  fill-opacity: 0.3;
}
