.status {
  font-size: var(--font-12);
  line-height: var(--line-height-3xs);
  text-wrap: nowrap;

  padding: var(--gap-5xs) var(--gap-3xs);
  margin-right: var(--gap-3xs);

  border: 1px solid transparent;
  border-radius: var(--radius-xs);
}

.expired {
  color: var(--status-expired-text);
  border-color: var(--status-expired-border);
  background-color: var(--status-expired-bg);
}

.in_progress {
  color: var(--status-in_progress-text);
  border-color: var(--status-in_progress-border);
  background-color: var(--status-in_progress-bg);
}

.completed {
  color: var(--status-completed-text);
  border-color: var(--status-completed-border);
  background-color: var(--status-completed-bg);
}
