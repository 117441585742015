.table * {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
}

.table thead {
  background-color: var(--primary-light);
  border: none;
  border-bottom: 1px solid var(--primary-lighter);
}
.table thead * {
  font-weight: var(--regular);
}
.table thead th {
  padding: var(--gap-3xs) var(--gap-xs);
  border-bottom: none;
}

.table tbody tr td {
  padding: 0 var(--gap-sm);
  min-height: 4rem;
  border-bottom: 1px solid var(--primary-light);
}
