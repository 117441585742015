.main {
  height: 100%;
}

.select-block-container {
  margin-top: var(--gap-md);
}

.text-area-container {
  position: relative;
  margin-block: var(--gap-md);
}
