.wrapper {
  width: 100%;
  height: 40rem;
  padding: var(--gap-xl);

  display: flex;
  flex-direction: column;
  gap: var(--gap-md);

  overflow-y: auto;
  scrollbar-gutter: stable;
}

.empty {
  font-size: var(--font-12);
  align-self: center;
}

.loading {
  font-size: var(--font-12);
  text-align: center;
}
