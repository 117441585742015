.option {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
  color: var(--text-primary);
  padding: var(--gap-4xs) var(--gap-xs);
}

.selected {
  background-color: var(--primary-light) !important;
}
