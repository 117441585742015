a {
  text-decoration: none;
  color: var(--primary-color);
}

.container {
  display: flex;
}
.container.right {
  justify-content: end;
}

.avatar {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: var(--gap-5xs);
}

.message {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  padding: var(--gap-3xs) var(--gap-xs);
  border-radius: var(--radius-md);

  max-width: calc(100% - 4.6rem);
}

.message h3 {
  margin-bottom: var(--gap-3xs);
  text-decoration: underline;
}

.message ol,
.message ul {
  margin-left: var(--gap-md);
}

.message li {
  white-space: wrap;
  margin-left: var(--gap-md);
  line-height: var(--line-height-xs);
}

.message img {
  max-width: 100%;
}

.inner * {
  white-space: pre-wrap;
  text-wrap: wrap;

  overflow-wrap: anywhere;
  word-break: break-word; /* fallback */
}

.user {
  color: var(--text-primary);
  background-color: var(--bg-button-primary);

  justify-content: end;

  border-bottom-right-radius: 0;
}

.user .inner {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.assistant {
  color: var(--text-color);
  background-color: var(--bg-message);

  border-bottom-left-radius: 0;
}

.bottom-container {
  display: flex;
  align-items: end;

  height: 100%;
}

.copy-button {
  height: 2rem;
  width: 2rem;

  padding: 0;
  margin-left: var(--gap-5xs);
}
.copy-button path {
  stroke: var(--component-border-hover);
  transition: stroke 0.2s;
}
.copy-button:hover path {
  stroke: var(--primary-lighter);
}
.copy-button:active path {
  stroke: var(--primary-color);
}

.message pre {
  position: relative;
  display: flex;
  padding: var(--gap-xs);
  background-color: var(--bg-code);
  border-radius: var(--radius-md);
}

.message pre code {
  font-family: Consolas, "Courier New", monospace !important;
  font-size: var(--font-14);
  display: block;
}

.copy-code-icon {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  cursor: pointer;
}
.copy-code-icon path {
  transition: stroke 0.2s;
}
.copy-code-icon:hover path {
  stroke: var(--primary-lighter);
}
.copy-code-icon:active path {
  stroke: var(--primary-color);
}

.download-button {
  margin-left: var(--gap-5xs);
  margin-top: var(--gap-sm);
}

.file-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--gap-5xs) var(--gap-4xs);
  margin-top: var(--gap-3xs);
  gap: var(--gap-3xs);
  width: fit-content;

  background-color: var(--secondary-color);
  border-radius: var(--radius-sm);

  font-size: var(--font-12);
  color: var(--primary-color);
}
