html {
  font-size: 62.5%;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  overflow-y: hidden;
}

*,
*::before,
*::after {
  font-family: var(--font) !important;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 1.5rem;
  border-radius: 1rem;
}
*::-webkit-scrollbar-thumb {
  border: 0.6rem solid transparent;
  background-clip: padding-box;
  border-radius: 10rem;

  background-color: var(--primary-lighter);

  cursor: pointer;
}
