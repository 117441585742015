:root {
  --base-grey-5: #f2f4f5;
  --base-float: #17384c;

  --primary-color: #1d9f81;
  --secondary-color: #ffffff;
  --primary-lighter: #59c69e;
  --primary-light: #59c69e33;
  --error-color: #ff4d4f;
  --error-hover: #ff7a7a05;
  --error-bg: #fff1f0;

  --bg-main: #59c69e0d;
  --bg-secondary: #cae0d824;
  --bg-message: #f7f9f9;
  --bg-code: #ebeff0;
  --bg-send: #f7fcfa;
  --bg-sidebar: #0029410d;
  --bg-accordion: #00294108;
  --bg-stylist-result: #fafdfc;
  --bg-button-primary: #def4ec;
  --bg-menu: #0000000a;
  --bg-modal: #fcfffe;
  --bg-tag: #eef9f5;

  --border-primary: #def4ec;

  --text-primary: #0a3249;
  --text-placeholder: #0029415c;
  --text-label: #00294185;

  --button-border-color: #c9d2d7;
  --component-border-color: #00294126;
  --component-border-hover: #a3b2bb;
  --component-border-disabled: #ebebeb;
  --chat-border-color: #0029410a;
  --upload-zone-border-color: #c5d4e8;

  --status-expired-text: #eb2f96;
  --status-expired-bg: #fff0f6;
  --status-expired-border: #ffadd2;

  --status-in_progress-text: #1677ff;
  --status-in_progress-bg: #1677ff1a;
  --status-in_progress-border: #1677ff;

  --status-completed-text: #52c41a;
  --status-completed-bg: #52c41a1a;
  --status-completed-border: #52c41a;

  --icon-disabled: #00000042;
  --icon-arrow: #7a909c;
}
