.chat-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  height: 50%;
}

.bottom-container {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  padding: var(--gap-md) var(--gap-lg) var(--gap-md) var(--gap-lg);

  border-top: var(--component-border);
  background-color: var(--bg-send);
}
