@font-face {
  font-family: "Montserrat";
  src: url(../../../assets/fonts/Montserrat-Thin.woff2) format("woff2");
  font-weight: 100;
}

@font-face {
  font-family: "Montserrat";
  src: url(../../../assets/fonts/Montserrat-ExtraLight.woff2) format("woff2");
  font-weight: 200;
}

@font-face {
  font-family: "Montserrat";
  src: url(../../../assets/fonts/Montserrat-Light.woff2) format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: url(../../../assets/fonts/Montserrat-Regular.woff2) format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url(../../../assets/fonts/Montserrat-Medium.woff2) format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url(../../../assets/fonts/Montserrat-SemiBold.woff2) format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url(../../../assets/fonts/Montserrat-Bold.woff2) format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url(../../../assets/fonts/Montserrat-ExtraBold.woff2) format("woff2");
  font-weight: 800;
}
