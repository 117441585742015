.page-layout {
  display: flex;
  height: calc(100vh - var(--header-height));
}

.unban-button {
  font-weight: var(--regular);
}

.placeholder-container {
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
}
