.select {
  width: 100%;
}

.base-select {
  padding: var(--gap-3xs) var(--gap-xs);

  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  box-sizing: border-box;
  border: var(--component-border);
  border-radius: var(--radius-md) !important;
  background: var(--secondary-color);
}
.base-select.disabled {
  border-color: var(--component-border-disabled);
}

.icon {
  position: absolute;
  top: 50%;
  right: var(--gap-md);
  transform: translateY(-50%);
  pointer-events: none;
}
.icon.disabled path {
  stroke: var(--component-border-color);
}

.placeholder {
  color: var(--text-placeholder);
}

.menu-list {
  padding: var(--gap-5xs) 0;
  border-radius: var(--radius-xs);
}
