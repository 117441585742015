.container {
  flex: 1;
  height: 100%;

  padding: var(--gap-xl);
  padding-right: var(--gap-sm);

  border-right: var(--header-border);
  border-left: var(--header-border);

  overflow-y: auto;
  scrollbar-gutter: stable;
}

.block-container {
  margin-bottom: var(--gap-3xl);
}

.title {
  margin-bottom: var(--gap-md);
}

.bot-name {
  color: var(--text-primary);
  font-size: var(--font-20);
  font-weight: var(--semibold);
  line-height: var(--line-height-sm);

  margin-bottom: var(--gap-5xs);
}

.api-container {
  display: flex;
  align-items: center;
  gap: var(--gap-md);
  margin-bottom: var(--gap-md);
}

.bot-api {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap-5xs);
  color: var(--text-label);
}
@media (max-width: 1600px) {
  .bot-api {
    flex-direction: column;
  }
}

.bot-api-header {
  font-size: var(--font-16);
  font-weight: var(--semibold);
  line-height: var(--line-height-2xs);
  white-space: nowrap;
}

.bot-api-content {
  word-break: break-all;
  font-size: var(--font-16);
  line-height: var(--line-height-2xs);
}

.change-button {
  font-weight: var(--regular);
}

.label {
  margin-bottom: var(--gap-5xs);
}

.input {
  margin-bottom: var(--gap-3xs);
}

.input-base {
  margin-bottom: var(--gap-md);
}

.select {
  margin-bottom: var(--gap-md);
}

.user {
  margin-bottom: var(--gap-md);
}

.switch-container {
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
  margin-bottom: var(--gap-md);
}

.switch-label {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
}

.blacklist-switch-container {
  gap: var(--gap-3xs);
}

.blacklist-switch-label {
  color: var(--primary-color);
}
