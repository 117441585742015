.small {
  width: 14%;
  text-align: center;
}

.header-small {
  padding-right: 0 !important;
}

.assistant {
  display: flex;
  align-items: center;
  width: 100%;
  gap: var(--gap-3xs);
}

.row {
  transition: background-color 0.2s;
}

.hovered-row {
  background-color: var(--bg-secondary);
}

.hidden {
  pointer-events: none;
  opacity: 0;
}
