.dialog {
  display: flex;
  flex-direction: column;

  width: 46rem;
  padding: var(--gap-xl);
}

.dialog-title {
  font-size: var(--font-18);
  font-weight: var(--medium);
  line-height: var(--line-height-sm);

  margin-bottom: var(--gap-md);
}

.select {
  margin-bottom: var(--gap-md);
}

.dialog-button-container {
  display: flex;
  justify-content: end;
  gap: var(--gap-3xs);
}
