.error {
  position: absolute;
  top: 5rem;
  right: 5rem;
  z-index: 1000;

  display: flex;
  align-items: center;

  padding: var(--gap-2xs) var(--gap-sm);
  border-radius: var(--radius-sm);
  max-width: 40rem;

  opacity: 0;

  background-color: var(--error-color);

  transition: opacity 0.8s ease;
}

.message {
  display: flex;
  font-size: var(--font-14);
  color: var(--secondary-color);
  margin-right: var(--gap-lg);
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 0.2rem;
  cursor: pointer;

  background-color: transparent;
  border: none;
}
.close-button:focus {
  outline: none;
}

.close-button path {
  stroke: var(--secondary-color);
}
