.message-sending-wrapper {
  width: 100%;
  min-height: 22rem;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
  gap: var(--gap-xs);

  border-top: 0.1rem solid var(--border-primary);
  background: var(--bg-send);
}

.message-sending {
  width: 100%;
  display: flex;
  align-items: end;
  gap: var(--gap-2xs);
}

.center-block {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
  flex-grow: 2;
}

.send-button {
  position: absolute;
  right: 0;
  bottom: 0;
}

.send-button path {
  stroke: var(--secondary-color);
}

.chat-button-container {
  justify-content: end;
}

.message-sending-footer {
  justify-content: start;
}

.clear-button {
  padding-inline: 0.4rem;
}

.chat-input {
  padding-right: 0.4rem;

  background: var(--secondary-color);

  overflow-y: auto;
  scrollbar-gutter: stable;
  scrollbar-width: none;
}
