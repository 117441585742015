.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--gap-md);

  border-radius: var(--radius-xl);
  background-color: var(--bg-secondary);
}

.chat {
  display: flex;
  flex-direction: column;
  gap: var(--gap-lg);
  padding: var(--gap-xl) 0;

  overflow-y: auto;

  scrollbar-gutter: stable;
}

.loading {
  text-align: center;
  font-size: var(--font-12);
}
