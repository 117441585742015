.modal-content {
  position: relative;
  width: 50rem;
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 var(--gap-xl) var(--gap-xl);

  border-radius: var(--radius-lg);
  background: var(--bg-send);
}
