.container {
  width: 100%;
  height: 100%;
  overflow: auto;

  padding: var(--gap-xl);
  padding-right: var(--gap-sm);

  border-left: var(--header-border);

  overflow-y: auto;
  scrollbar-gutter: stable;
}

.title {
  margin-bottom: var(--gap-3xs);
}
.title:first-child {
  margin-bottom: var(--gap-md);
}

.label {
  margin-bottom: var(--gap-5xs);
}

.input {
  margin-bottom: var(--gap-md);
}

.select-container {
  display: flex;
  gap: var(--gap-3xs);

  margin-bottom: var(--gap-3xl);
}

.file-list {
  margin-bottom: var(--gap-3xl);
}

.file-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 var(--gap-3xs);
  margin-bottom: var(--gap-3xs);
}
.file-list-item:last-child {
  margin-bottom: 0;
}

.file-name-container {
  display: flex;
  align-items: center;
  gap: var(--gap-3xs);

  color: var(--primary-color);
  font-size: var(--font-14);
}

.upload-button {
  margin-bottom: var(--gap-3xl);
}

.catalog-button {
  color: var(--text-primary);
  font-weight: var(--regular);
}

.catalog-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: var(--gap-3xs);
}

.placeholder {
  color: var(--text-placeholder);
  font-size: var(--font-14);
  text-align: center;

  padding: var(--gap-3xs) 0;
}

.upload-input {
  display: none;
}
