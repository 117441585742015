.container {
  flex: 1;

  padding: var(--gap-xl);
  padding-right: var(--gap-sm);

  overflow-y: auto;
  scrollbar-gutter: stable;
}

.title {
  margin-bottom: var(--gap-md);
}

.label {
  margin-bottom: var(--gap-5xs);
}

.select {
  margin-bottom: var(--gap-md);
}

.date-container {
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
  margin-bottom: var(--gap-md);
}

.date-label {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
  text-wrap: nowrap;
}

.date-warning {
  text-align: center;
  color: var(--error-color);
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
  margin-bottom: var(--gap-md);
}

.accordion {
  margin-bottom: var(--gap-5xs);
}

.accordion-blocked {
  background-color: var(--error-bg) !important;
}

.ban-button {
  font-weight: var(--regular);
}

.ban-button-blocked {
  color: var(--error-color);
}
.ban-button-blocked path {
  stroke: var(--error-color);
}

.chat {
  min-height: 32rem;
  height: 32rem;
  resize: vertical;
  overflow-y: auto;
}

.chat-input {
  background-color: var(--secondary-color);
}

.no-chats-label {
  margin-top: var(--gap-xl);
  text-align: center;
}
