.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 32rem;
  padding: var(--gap-xl);
}

.warning-container {
  display: flex;

  margin-bottom: var(--gap-xl);
}

.warning-icon {
  width: 5rem;
  height: 2.4rem;
  margin-right: var(--gap-xs);
}

.warning {
  font-size: var(--font-18);
  font-weight: var(--medium);
  line-height: var(--line-height-sm);

  text-wrap: wrap;
}

.name {
  word-break: break-all;
}

.dialog-button-container {
  display: flex;
  justify-content: space-around;
  gap: var(--gap-3xs);
}
