.container {
  width: 100%;
  overflow-y: auto;
}

.header {
  padding-inline: var(--gap-2xs);
}

.chat-container {
  width: 100%;
}

.title {
  position: relative;
  padding-inline: var(--gap-xl);
  margin: 0 var(--gap-xl);
}

.clear path {
  stroke: var(--primary-color);
}
