.sidebar {
  min-width: 24rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-block: var(--gap-xs);

  background: var(--base-grey-5);
}

.sidebar-header {
  margin-inline: var(--gap-xs);
  border-bottom: var(--default-border);
}

.loader {
  margin-bottom: var(--gap-xs);
}

.sidebar-body {
  height: 90%;
  flex-shrink: 2;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

.button-container {
  padding: 0.6rem 0;
}

.list {
  position: relative;
  padding-top: var(--gap-md);
  padding-left: var(--gap-xs);
  list-style: none;
  height: 90%;
  width: 100%;
}

.list-item {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--gap-3xs) var(--gap-5xs) var(--gap-3xs) var(--gap-xs);

  min-height: 3.2rem;
  width: 100%;
  word-break: break-all;

  color: var(--text-primary);

  border-radius: var(--radius-md);

  z-index: 10;
}
.list-item.selected {
  color: var(--primary-color);
  background-color: var(--bg-button-primary);
  border-radius: var(--radius-md);
}

.arrow {
  background-image: url("../../shared/assets/svg/chevron-right.svg");
  background-repeat: no-repeat;
  background-position: 95%;
}

.list-item.selected path {
  stroke: var(--primary-color);
}
.list-item:last-child {
  margin-bottom: 0;
}

.delete-button {
  padding: var(--gap-4xs);
  margin-right: var(--gap-5xs);
}

.item-name {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  width: 100%;
  max-width: 18rem;

  cursor: pointer;
  user-select: none;
}

.disabled {
  color: var(--text-placeholder);
}
.disabled path {
  stroke: var(--icon-disabled);
}

@media screen and (max-height: 800px) {
  .sidebar-body {
    height: 80%;
  }
}
