.content {
  display: flex;
  gap: var(--gap-3xs);
}

.button {
  font-size: var(--font-14);
  font-weight: var(--medium);
  line-height: var(--line-height-2xs);

  padding: var(--gap-5xs);
}

.left-container {
  padding-top: var(--gap-3xs);
  width: 36%;
  min-width: 21rem;
}

.select {
  margin-bottom: var(--gap-3xs);
}

.right-container {
  padding: var(--gap-3xs);
  flex: 1;

  background-color: var(--bg-secondary);

  border-radius: var(--radius-lg);
}

.top-input {
  margin-bottom: var(--gap-3xs);
}
