.block-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-5xs);
}

.select-container {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-3xs);

  flex-grow: 1.5;
}

.label {
  color: var(--text-primary);
  font-size: var(--gap-xs);
  line-height: var(--line-height-3xs);
}

.select {
  background: var(--secondary-color);
}

.settings {
  flex-grow: 1.2;
}
