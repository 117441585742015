.text-area {
  height: auto;
}

.text-area-inner {
  padding-right: 3.5rem;
  position: relative;
  resize: vertical;

  overflow-y: hidden;
}
