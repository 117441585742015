.root {
  font-size: var(--font-14);
  border: none;
  width: 100%;
}
.root:hover {
  border: none;
}

.root > div > fieldset {
  border: none;
}

.root > div {
  border: var(--component-border);
  border-radius: var(--radius-md);
}

.root > div > input {
  font-size: var(--font-16);
  line-height: var(--line-height-2xs);
  max-height: 1.8rem;
  padding: var(--gap-2xs) var(--gap-xs);
}

.day-button:focus {
  background-color: var(--primary-light);
}
.day-selected {
  background-color: var(--primary-color) !important;
}

.paper * {
  font-size: var(--font-12);
}

.calendar-header * {
  font-size: var(--font-14);
  text-transform: capitalize;
}
