.upload-input {
  display: none;
}

.chat-button {
  width: 4rem;
  height: 4rem;
}

.chat-button path {
  stroke: var(--secondary-color);
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
}
