.button {
  padding: 0;
  width: 3.2rem;
  height: 3.2rem;

  color: var(--primary-color);

  border: 1px solid transparent;
  border-radius: var(--radius-sm) !important;

  transition: background-color 0.2s, color 0.2s, border 0.2s, opacity 0.2s;
}

.contained {
  color: var(--secondary-color);
  background-color: var(--primary-lighter);
}
.contained:hover {
  background-color: var(--primary-lighter);
}

.contained path {
  stroke: var(--secondary-color);
}

.outlined {
  background-color: var(--secondary-color);

  border: var(--button-border);
}
.outlined:hover {
  background-color: var(--secondary-color);
  border-color: var(--primary-color);
}

.outlined path {
  stroke: var(--primary-color);
}

.text {
  min-width: 0;
}
.text:hover {
  background-color: transparent;
}

.text path {
  stroke: var(--primary-color);
}

.big {
  width: 4rem;
  height: 4rem;
}

.warning {
  color: var(--error-color);
}

.outlined.warning:hover {
  border-color: var(--error-color);
}

.disabled path {
  stroke: var(--icon-disabled);
}
