.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--gap-md);

  border-radius: var(--radius-xl);
  background-color: var(--bg-secondary);
}

.title {
  margin-bottom: var(--gap-md);
}

.model-button {
  font-size: var(--font-16);
  line-height: var(--line-height-2xs);
  padding: var(--gap-4xs);
}

.button-container {
  display: flex;

  gap: var(--gap-3xs);

  padding-right: var(--gap-4xs);
}

.chat-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-lg);

  height: 100%;
  overflow-y: auto;

  padding: var(--gap-md);
  padding-right: 0.2rem;

  border-radius: var(--radius-lg);
  border: var(--chat-border);

  background-color: var(--bg-chat);

  scrollbar-gutter: stable;
}

.loading {
  text-align: center;
  font-size: var(--font-12);
}

.disabled path {
  stroke: var(--text-placeholder);
}
