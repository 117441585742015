.placeholder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  width: 100%;

  padding: 6rem 4.8rem 4.8rem;

  background-color: var(--bg-main);

  overflow-y: auto;
  scrollbar-gutter: stable;
  transition: all 0.3s ease;
}

.text-container {
  display: flex;
  align-items: center;
  gap: var(--gap-3xs);
}

.text {
  color: var(--text-primary);
  font-size: var(--font-20);
  font-weight: var(--regular);
  line-height: var(--line-height-title);
}

.icon {
  width: 3rem;
  height: 3rem;
}

.chart {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);

  padding: 5rem;

  width: clamp(40rem, 100%, 70rem);
  height: 50rem;
  opacity: 0.1;
}

.links-title {
  font-size: var(--font-20);
  line-height: var(--line-height-lg);

  margin-bottom: var(--gap-xs);
}

.links {
  display: flex;
  justify-content: space-between;
}

.links a {
  color: var(--primary-color);

  font-size: var(--font-16);
  line-height: var(--line-height-sm);

  text-decoration: none;
}
