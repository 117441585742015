.upload-zone {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xs);

  border-radius: var(--radius-lg);
  border: 0.1rem dashed var(--upload-zone-border-color);
  transition: border-color 0.2s, color 0.2s, border 0.2s, opacity 0.2s;
}

.upload-button {
  width: 4rem;
  height: 4rem;

  background: var(--secondary-color);
  border: 0.1rem dashed var(--upload-zone-border-color);
}

.upload-button:hover {
  background: var(--bg-send);
  border-color: var(--primary-lighter);
}

.upload-zone-text {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
  color: var(--primary-color);
}

.upload-zone-text {
  transition: color 0.2s, color 0.2s, border 0.2s, opacity 0.2s;
}

.dragging {
  border-color: var(--primary-lighter);
  transition: border-color 0.2s, color 0.2s, border 0.2s, opacity 0.2s;
}

.upload-zone-text-drag {
  color: var(--icon-disabled);
  transition: color 0.2s, color 0.2s, border 0.2s, opacity 0.2s;
}

.upload-input {
  display: none;
}
