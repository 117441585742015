.button {
  font-size: var(--font-12);
  border-radius: var(--radius-md);

  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}

.selected {
  background-color: var(--bg-button-primary) !important;
  color: var(--primary-color) !important;
  border-color: transparent;
}

.disabled {
  opacity: 0.7;
}
