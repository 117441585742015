.switch {
  width: 3.8rem;
  height: 2.2rem;
  padding: 0;
}

.switchBase {
  padding: 0;
  margin: 0.2rem;
  transition: transform 300ms ease-in-out;
}

.switchBase.checked {
  transform: translateX(16px);
  color: #fff;
}

.switchBase.checked + .track {
  background-color: var(--primary-lighter);
  opacity: 1;
}

.switchBase.checked.disabled + .track {
  opacity: 0.5;
}

.switchBase.disabled + .thumb {
  color: var(--bg-secondary);
}

.switchBase.disabled + .track {
  opacity: 0.7;
}

.thumb {
  width: 1.8rem;
  height: 1.8rem;
}

.track {
  border-radius: 1.1rem;
  background-color: var(--component-border-color);
  opacity: 1;
  transition: background-color 500ms;
}
