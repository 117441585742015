.resize {
  position: relative;
  width: 1px;
  height: 100%;
  overflow-y: auto;

  scrollbar-gutter: stable;
  background: var(--primary-light);
  z-index: 1000;
}

.resize-panel-left-horizontal {
  position: relative;
  overflow-y: auto !important;
  scrollbar-gutter: stable;
}

.resize-panel-horizontal {
  position: relative;
  min-width: 50rem;
  overflow-y: auto !important;
  scrollbar-gutter: stable;
}

.collapsed svg {
  transform: rotate(270deg);
}

.resize-panel-horizontal::after {
  content: "";
  position: absolute;
  top: 40%;
  left: 0;
  width: 0.5rem;
  height: 5rem;

  cursor: col-resize;

  background: var(--primary-light);
}
