.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--gap-md);

  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
  color: var(--primary-color);
  text-align: center;
}

.linear-loading span > span {
  background: var(--primary-color);
}

.linear-loading > span {
  background: var(--bg-code);
}
