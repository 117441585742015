.item-wrapper {
  display: flex;
}

.account-menu-item {
  display: flex;
  justify-content: start;
  gap: var(--gap-xs);
  width: 100%;

  font-size: var(--font-14);
  color: var(--text-primary);
}

.logout {
  margin-left: auto;
}

.item-wrapper:hover {
  background-color: var(--bg-menu);
}
