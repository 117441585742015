.layout-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);

  padding: var(--page-padding);
  height: calc(100vh - var(--header-height));
}

.chat-container {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-3xs);
  flex-grow: 1;

  width: 100%;

  overflow-y: auto;
}

.input {
  margin-bottom: var(--gap-3xs);
}

.request-container {
  text-align: end;
}
