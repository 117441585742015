.progress-bar {
  position: relative;
  width: 20rem;
  height: 20rem;
}

.progress-bar-content {
  position: absolute;
  inset: 0;
  display: flex;

  padding: var(--gap-md);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap-3xs);
  text-align: center;
}

.loading circle {
  stroke: var(--primary-lighter);
}

.progress-bar-text {
  font-size: var(--font-30);
  line-height: var(--line-height-title);
  color: var(--text-primary);
  text-align: center;
}

.progress-bar-filename {
  font-size: var(--font-12);
  line-height: var(--line-height-3xs);
  color: var(--text-primary);
  overflow-wrap: anywhere;
}

.progress-bar-border {
  border-radius: 100%;
  outline: 0.95rem solid var(--bg-code);
  outline-offset: -10px;
}
