.dialog {
  padding: var(--gap-md);
  width: 32rem;
}

.title {
  font-size: var(--font-18);
  font-weight: var(--semibold);
  line-height: var(--line-height-sm);

  padding: 0;
  margin-bottom: var(--gap-lg);
}

.select {
  margin-bottom: var(--gap-lg);
}
.select div {
  background-color: transparent;
}

.placeholder {
  opacity: 0.5;
  font-weight: var(--regular);
}

.button-container {
  display: flex;
  justify-content: end;
  gap: var(--gap-3xs);
}

.button-container button {
  min-width: 11.2rem;
  padding: var(--gap-4xs);
}
