.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem var(--gap-4xs);
  gap: var(--gap-3xs);

  background-color: var(--bg-tag);
  border: var(--default-border);
  border-color: var(--primary-lighter);
  border-radius: var(--radius-sm);

  font-size: var(--font-12);
  color: var(--primary-color);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  font-size: var(--font-16);
  color: var(--primary-color);
}
