.hide-button {
	position: absolute;
	top: 1.6rem;
	left: 0;
	width: 2rem;
	height: 4.4rem;
	padding-inline: 0;

	vertical-align: middle;
	background: transparent;
	border: 1px solid var(--button-border-color);
	border-radius: 0 var(--gap-4xs) var(--gap-4xs) 0 !important;
	cursor: pointer;
}

.icon {
	transform: rotate(90deg);
}

.hide-button path {
	stroke: var(--icon-arrow);
}
