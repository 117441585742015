.modal {
  border-radius: var(--radius-main);
  max-width: 80rem;
  background: var(--bg-modal);
}

.modal-container {
  overflow-x: hidden;
  scrollbar-gutter: stable;
}

.modal-container::-webkit-scrollbar {
  display: none;
}

.header-padding {
  padding-bottom: 0 !important;
}
