.button {
  text-transform: none;
  font-size: var(--font-16);
  font-weight: var(--medium);
  line-height: var(--line-height-xs);

  color: var(--primary-color);

  min-width: 12rem;
  padding: var(--gap-2xs) var(--gap-xs);

  border-radius: var(--radius-sm);

  box-shadow: none;

  transition: background-color 0.2s, color 0.2s, border 0.2s, opacity 0.2s;
}
.button:hover {
  opacity: 0.6;
  box-shadow: none;
}
.button path {
  transition: fill 0.2s;
}

.contained {
  color: var(--secondary-color);
  background-color: var(--primary-lighter);
}
.contained:hover {
  background-color: var(--primary-lighter);
}

.contained path {
  stroke: var(--secondary-color);
}

.outlined {
  background-color: var(--secondary-color);

  border: var(--button-border);
}
.outlined:hover {
  background-color: var(--secondary-color);
  border-color: var(--primary-lighter);
}

.outlined path {
  stroke: var(--primary-color);
}

.text {
  min-width: 0;
}
.text:hover {
  background-color: transparent;
}

.text path {
  stroke: var(--primary-color);
}

.small {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  padding: 0.7rem var(--gap-xs);
}
.small .icon {
  margin: 0 var(--gap-5xs) 0 0;
}

.icon path {
  transition: stroke 0.2s;
}

.warning {
  color: var(--error-color);
}

.outlined.warning:hover {
  border-color: var(--error-color);
}

.disabled .icon path {
  stroke: var(--component-border-color);
}
