.header-container {
  display: flex;
  align-items: center;
  gap: var(--gap-3xs);
  width: 100%;
}

.input {
  width: 40%;
}

.input input {
  padding-block: var(--gap-3xs);
}
