.container {
  display: flex;

  align-items: end;
}
.container.right {
  justify-content: end;
}

.message {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  padding: var(--gap-3xs) var(--gap-xs);
  border-radius: var(--radius-md);
}
.message li {
  margin-left: var(--gap-md);
}

.inner * {
  white-space: pre-wrap;
  text-wrap: wrap;
}

.user {
  color: var(--secondary-color);
  background-color: var(--primary-lighter);

  justify-content: end;

  border-bottom-right-radius: 0;
}

.assistant {
  color: var(--text-color);
  background-color: var(--secondary-color);

  border-bottom-left-radius: 0;
}

.copy-button {
  height: fit-content;

  padding: 0;
  margin-bottom: var(--gap-3xs);
}
.copy-button path {
  stroke: var(--primary-color);
}
