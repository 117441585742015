:root {
  --gap-5xs: 0.4rem;
  --gap-4xs: 0.6rem;
  --gap-3xs: 0.8rem;
  --gap-2xs: 1rem;
  --gap-xs: 1.2rem;
  --gap-sm: 1.4rem;
  --gap-md: 1.6rem;
  --gap-ml: 1.8rem;
  --gap-lg: 2rem;
  --gap-xl: 2.4rem;
  --gap-2xl: 2.8rem;
  --gap-3xl: 3rem;
  --gap-4xl: 3.6rem;
  --gap-5xl: 4rem;

  --header-height: 5.6rem;

  --border-padding: calc(var(--gap-3xs) - 0.1rem);
}
