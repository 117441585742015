.title {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: var(--font-16);
  font-weight: var(--semibold);
  line-height: var(--line-height-xs);
  color: var(--text-primary);

  min-height: 4rem;

  border-bottom: var(--default-border);
}

.left-content {
  width: 60%;
}

.absolute {
  display: flex;
  gap: var(--gap-md);
  right: 0;
}
