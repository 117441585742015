.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  position: relative;
  padding: var(--gap-xs) var(--gap-xl);
  margin: 0 var(--gap-xl);
}

.clear path {
  stroke: var(--primary-color);
}

.pagination-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.chat {
  height: 100%;
  padding-inline: var(--gap-xl) !important;
  resize: vertical;
}

.file-container {
  display: flex;
  align-items: center;
  gap: var(--gap-xs);

  margin-bottom: var(--gap-3xs);
}

.assist-bottom-container {
  height: 100%;
  display: flex;
  align-items: stretch;
  gap: var(--gap-2xs);
}

.input {
  height: 100%;
  display: flex;
  align-items: stretch;
  background-color: var(--secondary-color);
}

.upload-input {
  display: none;
}

.disabled path {
  stroke: var(--icon-disabled);
}

.loading {
  margin: 0;
}
