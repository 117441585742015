.page-layout {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;

  background: linear-gradient(212.44deg, #59c69e 30.05%, #1d9f81 100%);
}

.logo {
  position: absolute;
  top: 4.8rem;
  left: 4.8rem;

  width: 8.6rem;

  user-select: none;
}

.title {
  font-size: var(--font-30);
  font-weight: var(--semibold);
  line-height: var(--line-height-title);
  color: #ffffff;
}

.background-squares {
  position: absolute;
  right: 0;
  bottom: 0;

  width: 21rem;

  user-select: none;
}
