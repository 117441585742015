.slider {
  padding: 0.8rem 0;
}

.rail {
  background-color: var(--component-border-color);
}

.track {
  background-color: var(--primary-color);

  border: none;
}

.thumb {
  background-color: var(--secondary-color);

  width: 1.8rem;
  height: 1.8rem;

  border: 0.3rem solid var(--primary-color);
}
.thumb:hover {
  box-shadow: none;
}
.thumb.active {
  box-shadow: none;
}
.thumb::before,
.thumb::after {
  display: none;
}
