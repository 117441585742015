.chat-modal {
  border-top: 0.1rem solid var(--primary-color);
}

.chat-modal > div:first-child {
  padding: var(--gap-xl);
}

.content-header {
  padding-inline: var(--gap-xl);
  display: flex;
  justify-content: end;
}

.body {
  width: 80rem;
  border-top: 0.1rem solid var(--border-primary);
}

.send-message-container {
  padding: var(--gap-xl);
}

.select-container {
  margin: 0;
}
