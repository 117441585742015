:root {
  --font: "Montserrat", sans-serif;

  --font-4: 0.4rem;
  --font-8: 0.8rem;
  --font-12: 1.2rem;
  --font-13: 1.3rem;
  --font-14: 1.4rem;
  --font-15: 1.5rem;
  --font-16: 1.6rem;
  --font-18: 1.8rem;
  --font-20: 2rem;
  --font-24: 2.4rem;
  --font-28: 2.8rem;
  --font-30: 3rem;
  --font-32: 3.2rem;
  --font-36: 3.6rem;

  --bold: 700;
  --semibold: 600;
  --medium: 500;
  --regular: 400;

  --line-height-3xs: 1.4rem;
  --line-height-2xs: 1.8rem;
  --line-height-xs: 2rem;
  --line-height-sm: 2.4rem;
  --line-height-lg: 2.8rem;
  --line-height-title: 3.8rem;
}
