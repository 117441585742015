.absolute {
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
}

.static {
  position: static;
}

.copy-button path {
  stroke: var(--component-border-hover) !important;
}

.copy-button:hover path {
  stroke: #808080 !important;
}

.copy-button:active svg {
  filter: drop-shadow(0 0 1rem var(--primary-color)) !important;
}
