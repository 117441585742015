.result {
  height: 100%;
  background: var(--bg-stylist-result);
}

.date {
  font-family: inherit;

  color: var(--icon-arrow);
  font-size: var(--font-14);
}

.text-area-container {
  position: relative;
  margin-block: var(--gap-md);
}

.loading {
  position: absolute;
  top: calc(50% - 2.5rem);
  z-index: 99;
}

.button-clean {
  align-self: flex-end;
}
