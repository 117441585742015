.page-layout {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;

  background: linear-gradient(212.44deg, #59c69e 30.05%, #1d9f81 100%);
}

.logo-container {
  position: absolute;
  top: 4.8rem;
  left: 4.8rem;

  width: 8.6rem;
  display: flex;
  flex-direction: column;
  gap: var(--gap-3xs);
}

.logo {
  user-select: none;
}

.version {
  align-self: center;

  color: var(--secondary-color);
  font-size: var(--font-12);
}

.background-squares {
  position: absolute;
  right: 0;
  bottom: 0;

  width: 21rem;

  user-select: none;
}
