:root {
  --radius-main: 2.4rem;
  --radius-xl: 1.6rem;
  --radius-lg: 1.2rem;
  --radius-md: 0.8rem;
  --radius-sm: 0.6rem;
  --radius-xs: 0.4rem;
  --radius-2xs: 0.3rem;

  --page-padding: 3.2rem;

  --button-container: 10.6rem;
  --date-container: 10rem;
  --graph-container-w: 17rem;
  --graph-container-h: 7rem;

  --login-shadow: 0px 2px 4px 0px #0029410a, 0px 1px 6px -1px #0029410a,
    0px 1px 2px 0px #00294105;
  --default-shadow: 0px 0px 0px 2px #e6f4ff;

  --default-border: 1px solid var(--primary-color);
  --component-border: 1px solid var(--component-border-color);
  --button-border: 1px solid var(--button-border-color);
  --header-border: 1px solid #59c69e33;
  --chat-border: 1px solid var(--chat-border-color);
}
