.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  width: 100%;

  padding: 4.8rem;

  background-color: var(--bg-main);

  transition: background-color 0.3s ease;
}

.top-container {
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin-bottom: var(--gap-xl);
}

.title {
  font-size: var(--font-30);
  font-weight: var(--semibold);
  line-height: var(--line-height-title);
}

.button-container {
  display: flex;
  gap: var(--gap-3xs);
}

.graph-container {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;

  padding: var(--gap-lg);
}

.links-title {
  font-size: var(--font-20);
  line-height: var(--line-height-lg);

  margin-bottom: var(--gap-xs);
}

.links {
  display: flex;
  justify-content: space-between;
}

.links a {
  color: var(--primary-color);

  font-size: var(--font-16);
  line-height: var(--line-height-sm);

  text-decoration: none;
}

.hidden {
  display: none;
}
