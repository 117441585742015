.container {
  position: relative;
  width: 46rem;
  padding: var(--gap-5xl);
  z-index: 5;

  text-align: center;

  background-color: var(--secondary-color);

  border-radius: var(--radius-main);

  box-shadow: var(--login-shadow);
}

.title {
  font-size: var(--font-30);
  font-weight: var(--semibold);
  line-height: var(--line-height-title);

  margin-bottom: 4.8rem;
}

.input {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  border: var(--component-border);
  border-radius: var(--radius-sm);

  padding: 0 var(--gap-xs);
  margin-bottom: var(--gap-3xl);
}
.input:last-child {
  margin-bottom: var(--gap-xl);
}

.inner-input {
  padding: var(--gap-3xs) 0;
}

.eye-icon-button {
  padding: 0.5rem;
}
.eye-icon path {
  fill: var(--text-placeholder);
}

.checks-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: var(--gap-xl);
}

.error {
  position: absolute;
  font-size: var(--font-14);
  font-weight: var(--medium);
  line-height: var(--line-height-2xs);

  right: 0;
  left: 0;

  color: var(--error-color);

  margin-bottom: var(--gap-3xl);
}

.forgot-password {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  color: var(--primary-color);

  text-decoration: none;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-button {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  padding: var(--gap-3xs) 5.4rem;

  margin-bottom: var(--gap-3xs);
}

.register-button {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
}

.background-squares {
  position: absolute;
  right: 0;
  bottom: 0;

  width: 21rem;

  user-select: none;
}
