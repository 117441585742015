.left-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 50%;

  padding: var(--gap-xl) 0;
}

.card-container {
  overflow-y: auto;
  padding-right: var(--gap-4xs);

  scrollbar-gutter: stable;
}

.title {
  margin-bottom: var(--gap-xl);
}

.card {
  margin-bottom: var(--gap-3xs);
}
.card:last-child {
  margin-bottom: 0;
}
