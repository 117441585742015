.container {
  border: var(--component-border);
  border-radius: var(--radius-md);
}

.input {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
  color: var(--text-primary);

  padding: var(--gap-4xs) var(--gap-xs);
  height: fit-content;
}

.small {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
}

.disabled {
  border-color: var(--component-border-disabled);
}
