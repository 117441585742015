.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;

  padding: 0 var(--gap-xl);
  height: var(--header-height);

  border-bottom: var(--header-border);
}

.nav-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  width: 8.6rem;

  margin-right: 2.4rem;
}

.header-link,
.name {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);

  color: var(--text-primary);
}

.header-link {
  display: flex;
  align-items: center;
  text-decoration: none;

  height: 100%;
  padding: 0 var(--gap-2xs);

  border-bottom: 2px solid transparent;
}

.header-link.active {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.login-container {
  height: 100%;
  display: flex;
  align-items: center;
  gap: var(--gap-3xs);
}

.user-info {
  display: flex;
  align-items: center;
}

.login-button path {
  stroke: white;
}

.name {
  font-size: var(--font-16);
  line-height: var(--line-height-xs);
  color: var(--text-primary);
  font-weight: 400;

  margin-right: var(--gap-xl);
}

.version {
  font-size: var(--font-14);
  color: var(--component-border-hover);
}
