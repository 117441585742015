.container {
  width: 100%;
  height: 100%;
  padding: var(--gap-md);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap-3xs);
}

.processing {
  background: transparent;
  align-items: center;
}

.ready {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap-3xs);
}

.loading,
.error,
.ready {
  font-size: var(--font-14);
  line-height: var(--line-height-2xs);
  color: var(--primary-color);
  text-align: center;
}
